import { ReactElement } from 'react';

import { GetStaticProps } from 'next';
import Script from 'next/script';

import { LandingPage, LandingPageLayout } from '@features/landing-page';

export const getStaticProps: GetStaticProps = ({ locale }) => {
  return {
    props: {
      messages: {
        ...require(`../public/locales/${locale}.json`)
      }
    }
  };
};

LandingPage.getLayout = function getLayout(page: ReactElement) {
  return (
    <>
      <Script
        async
        strategy="lazyOnload"
        src="https://static.aim.front.ai/prod-common/chat-formation.min.js"
        id="showChat"
        onLoad={() => (
          <Script id="show-chat">{chatFormation('prod-common-sejfa')}</Script>
        )}
      />
      <LandingPageLayout>{page}</LandingPageLayout>;
    </>
  );
};

export default LandingPage;
